import React from 'react'
import Helmet from 'react-helmet'
import { Link, StaticQuery, graphql } from 'gatsby'
import _get from 'lodash/get'
import AlertTriangle from 'react-feather/dist/icons/alert-triangle'

import Layout from '../components/Layout'

export default ({ children }) => (
  <StaticQuery
    query={graphql`
      query PPolicyQuery {
        globalSettings: settingsYaml {
          siteTitle
        }
      }
    `}
    render={data => (
      <Layout>
        <Helmet>
          <title>Privacy Policy | Coders of Colour</title>
        </Helmet>
        <section className="section thick">
          <div className="container skinny">
            {/* <p>
              <AlertTriangle size="5rem" />
            </p> */}
            <h1>CODERS OF COLOUR</h1>
            <h1>PRIVACY NOTICE</h1>

            <strong><p>1. The personal information we use</p>
            <p>1.1	Information we collect directly from you</p>
            <p>1.2	Information we collect from other sources</p>
            <p> 1.3.	Other information we use about you</p>
            <p> 1.4	Special categories of personal data</p>
            <p>2.	How we use your personal information and the basis on which we use it</p>
            <p>3.	Your rights over your personal information</p>
            <p>4.	Automated decisions about you</p>
            <p>5.	Information Sharing</p>
            <p>6.	Information Security and Storage</p>
            <p>7.	International Data Transfer</p>
            <p>8.	Contact Us</p>
            <p>9.	Changes to the Policy</p></strong>

                <p >This privacy notice describes how Coders of Colour CIC (&apos;we&apos;, &apos;us&apos;) collects and processes personal information about you, how we use and protect this information, and your rights in relation to this information.&nbsp;</p>
                <p >This privacy notice applies to all personal information we collect or process about you. Personal information is information, or a combination of pieces of information that could reasonably allow you to be identified.&nbsp;</p>
                <strong><p>Personal information we use</p></strong>
                <strong><p>Information we may collect directly from you</p></strong>
                <p >The categories of information that we collect directly from you are:</p>
                <ol type="a">
                    <li>personal details (e.g. name, gender, date of birth)&nbsp;</li>
                    <li>contact details (e.g. phone number, email address, postal address or mobile number)</li>
                    <li>images of you attending our events, together with any consents you may give us to share this information</li>
                    <li>Account details of users of our Online Learning Platform&nbsp;</li>
                    <li>Details of our volunteers&rsquo; experiences, previous roles, skills and interests</li>
                    <li>If you use our jobs board service, your CV and any other information you choose to upload</li>
                </ol>
                <strong><p>Other information we may use about you</p></strong>
                <ol type="a">
                    <li>event attendance records </li>
                    <li>details of your progress on our Online Learning Platform</li>
                    <li>details of how you interact with our website</li>
                   
                </ol>
                <strong><p>Special categories of personal data</p></strong>
                <div >
                    <ol type="a">
                        <li >Information about your racial or ethnic origin</li>
                    </ol>
                </div>
                <div >
                    <ol start="2">
                        <strong><li >How we use your personal information and the basis on which we use it</li></strong>
                    </ol>
                </div>
                <strong><p >We use your personal information to:</p></strong>
                <ol type="a">
                    <li>organise and run our events</li>
                    <li>provide, personalise and improve our other services, including our website, Online Learning Platform and jobs board</li>
                    <li>deal with your enquiries and requests</li>
                    <li>comply with legal obligations to which we are subject, and cooperate with regulators and law enforcement bodies</li>
                    <li>contact you with marketing and offers relating to products and services offered by us (unless you have opted out of marketing, or we are otherwise prevented by law from doing so)</li>
                    <li>personalise the marketing messages we send you to make them more relevant and interesting</li>
                    <li>provide reporting relating to the impact of our events and services (we only publish aggregate information for this purpose which does not identify you)</li>
                    <li>promote our services and events to others</li>
                </ol>
                <p >We must have a legal basis to process your personal information. In most cases the legal basis will be one of the following:</p>
                <ol type="a">
                    <li>to fulfil our contractual obligations to you, for example to provide the Online Learning Platform or our jobs board</li>
                    <li>to comply with our legal obligations, for example health and safety obligations</li>
                    <li>to meet our or a third partys legitimate interests, for example our legitimate interest in running our events. When we process personal information to meet our legitimate interests, we put in place robust safeguards to ensure that your privacy is protected and that our legitimate interests are not overridden by your interests or fundamental rights and freedoms</li>
                </ol>
                <p> We may obtain your explicit consent to collect and use certain types of personal information when we are required to do so by law (for example, in relation to our direct marketing activities). If we ask for your consent to process your personal information, you may withdraw your consent at any time by contacting us using the details at the end of this privacy notice.</p>
                <div>
                    <ol start="3">
                        <li >Your rights over your personal information</li>
                    </ol>
                </div>
                <p >You have certain rights regarding your personal information. These include rights to:</p>
                <ul>
                    <li >access your personal information</li>
                    <li >rectify the information we hold about you</li>
                    <li >erase your personal information</li>
                    <li >restrict our use of your personal information</li>
                    <li >object to our use of your personal information</li>
                    <li >receive your personal information in a usable electronic format and transmit it to a third party (right to data portability)</li>
                    <li >lodge a complaint with the Information Commissioner's Office.</li>
                </ul>
                <p >We encourage you to contact us to update or correct your information if it changes or if the personal information we hold about you is inaccurate.</p>
                <p >We will contact you if we need additional information from you in order to honour your requests.</p>
                <p >If you would like to discuss or exercise such rights, please contact us at the details below.</p>

                <ol start="4">
                    <strong><li>Automated decisions about you</li></strong>
                </ol>
                <p >The way we analyse personal information for the purposes of digital marketing may involve profiling, which means that we may process your personal information using software that is able to evaluate your personal aspects and predict outcomes. In particular, we may use profiling to select personalized recommendations to send you, for example based on your age.</p>
                <p >These types of decisions will not have a significant impact upon you, but you can still contact us for further information.</p>
                <div >
                    <ol start="5">
                        <strong><li >Information Sharing</li></strong>
                    </ol>
                </div>
                <p >We may share your personal information with third parties under the following circumstances:</p>
                <ul>
                    <li>if you use our jobs board service, we may share your information with prospective employers. We only do this when you ask us to and in accordance with your instructions.</li>
                    <li>service providers and business partners. We may share your personal information with our service providers and business partners that perform marketing services and other business operations for us. &nbsp;</li>
                    <li >law enforcement agency, court, regulator, government authority or other third party. We may share your personal information with these parties where we believe this is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights or the rights of any third party.</li>
                    <li >asset purchasers. We may share your personal information with any third party that purchases, or to which we transfer, all or substantially all of our assets and business. Should such a sale or transfer occur, we will use reasonable efforts to try to ensure that the entity to which we transfer your personal information uses it in a manner that is consistent with this Privacy Policy.&nbsp;</li>
                    </ul>
                <p>The recipients referred to above may be located outside the jurisdiction in which you are located (or in which we provide the services). See the section on International Data Transfer below for more information.</p>
                <div >
                    <ol start="6">
                        <strong><li >Information Security and Storage</li></strong>
                    </ol>
                </div>
                <p> We implement technical and organisational measures to ensure a level of security appropriate to the risk to the personal information we process. These measures are aimed at ensuring the on-going integrity and confidentiality of personal information. We evaluate these measures on a regular basis to ensure the security of the processing.&nbsp;</p>
                <p> We will keep your personal information for as long as we have a relationship with you. Once our relationship with you has come to an end, we will retain your personal information for a period of time that enables us to:</p>
                <ul>
                    <li> maintain business records for analysis and/or audit purposes</li>
                    <li> comply with record retention requirements under the law</li>
                    <li> defend or bring any existing or potential legal claims</li>
                    <li> deal with any complaints regarding the services</li>
                </ul>
                <p>We will delete your personal information when it is no longer required for these purposes. If there is any information that we are unable, for technical reasons, to delete entirely from our systems, we will put in place appropriate measures to prevent any further processing or use of our data.</p>
                <div>
                    <ol start="7">
                        <strong><li>International Data Transfer</li></strong>
                    </ol>
                </div>
                <p>Your personal information may be transferred to, stored, and processed in a country that is not regarded as ensuring an adequate level of protection for personal information under UK law.</p>
                <p> We have put in place appropriate safeguards (such as contractual commitments) in accordance with applicable legal requirements to ensure that your data is adequately protected. For more information on the appropriate safeguards in place, please contact us at the details below</p>
                <div>
                    <ol start="8">
                        <strong><li>Contact Us</li></strong>
                    </ol>
                </div>
                <p>Coders of Colour CIC is the controller responsible for the personal information we collect and process.</p>
                <p>If you have questions or concerns regarding the way in which your personal information has been used, please contact hello@codersofcolour.org.</p>
                <p>We are committed to working with you to obtain a fair resolution of any complaint or concern about privacy. If, however, you believe that we have not been able to assist with your complaint or concern, you have the right to make a complaint to the Information Commissioners Office using their website: <a href="https://ico.org.uk/make-a-complaint/">https://ico.org.uk/make-a-complaint/</a></p>
                <div>
                    <ol start="9">
                        <strong><li>Changes to the Policy</li></strong>
                    </ol>
                </div>
                <p> You may request a copy of this privacy notice from us using the contact details set out above. We may modify or update this privacy notice from time to time.</p>
                <p>If we change this privacy notice, we will notify you of the changes. Where changes to this privacy notice will have a fundamental impact on the nature of the processing or otherwise have a substantial impact on you, we will give you sufficient advance notice so that you have the opportunity to exercise your rights (e.g. to object to the processing).</p>

          </div>
        </section>
      </Layout>
    )}
  />
)
